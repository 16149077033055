export const transferProposalHash =
  "7a1646f8de73d1d15ddf19fec6a7512d1584e0763b2e95263c24c1af02a46be1";
export const withdrawProposalHash =
  "55167e2fe5d5cdb6e05752e62c6bff70138832847146f364180c442eae939cc2";
export const updateMainGovernanceProposalHash =
  "a7b80a27e3bb34d9ffd7c319e6b817f2169e77fcc2bd4e0ae27ba6074fbdf42e";
export const updateMainTreasuryProposalHash =
  "3f933ccad02bf81c0bfc0b680ecf7da9fdf4fcbbd5a1de29b8922aa1d8c490b6";
export const changeGovernanceProposalHash =
  "51e18c7e55fbdf74c48c15f2eb18ea72b36c20235d6588f2033034abd05adab6";
